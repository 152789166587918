<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="darken_green">
        <v-toolbar-title><h4 class="font-weight-light">TIME DEPOSIT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                <v-combobox
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  @change="get_search_items_info"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Search by (Account Name/Account #)"
                  ref="memberCombobox"
                  dense
                  outlined
                ></v-combobox>
                <v-text-field
                  v-model="account_no"
                  label="CMF No"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="account_name"
                  label="Account Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-col></v-col>
                <TimeDepositData v-on:data="calculation_of_time_deposit" :is_less="false"/>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="depositors_name"
                    label="Depositors Name"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
                class="d-none d-sm-flex justify-center position-relative"
              >
                <v-img
                  contain
                  max-width="170"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_time_deposit_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
import {mapGetters, mapActions} from "vuex";
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import TimeDepositData from './TimeDepositData'

export default {
  components: {
    snackBarDialog,
    TimeDepositData,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  data() {
    return {

      saving: false,
      alert: false,
      alert_message: '',

      search: '',
      search_member: '',
      search_items: [],
      balance: 0,
      terms: 0,

      account_id: '',
      account_no: '',
      account_name: '',
      amount: 0,
      depositors_name: '',

      interest: '',
      total_interest_earned: 0,
      total_deposit_with_interest: 0,
      maturity_date: '',
      maturity_month: '',
      total: '',
      last_name: '',
      first_name: '',
    }
  },
  watch: {
    search_member: function () {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.search_member == '') {
          this.search_items = []
        } else {
          this.searching_search_items(this.search_member)
        }
      }, 500)
    },
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    ...mapGetters('authentication', ['branch_id', 'user_id', 'branch', 'month_start', 'month_end', 'month_of']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('members_information', ['search_all_members']),
    ...mapActions('time_deposits', ['register_new_time_deposit_application']),
    reset() {
      this.saving = false
      this.alert = false
      this.alert_message = ''

      this.search = ''
      this.search_member = ''
      this.search_items = []

      this.account_id = 0
      this.account_no = ''
      this.account_name = ''
      this.balance = 0
      this.terms = 0

      this.amount = 0
      this.last_name = ''
      this.first_name = ''
    },
    calculation_of_time_deposit(data) {
      this.terms = data.terms
      this.amount = data.amount
      this.total_interest_earned = data.total_interest_earned
      this.total_deposit_with_interest = data.total_deposit_with_interest
      this.maturity_date = data.maturity_date
      this.maturity_month = data.maturity_month
    },
    searching(value) {
      this.search_member = value
    },
    searching_search_items(value) {
      this.search_all_members({
        search_word: value
      })
        .then(response => {
          this.search_items = response.data
        })
    },
    get_search_items_info() {
      if (this.search_items.length > 0) {
        var index = this.search_items.map(function (x) {
          return x.id;
        }).indexOf(this.search.id)
        if (index != -1) {
          this.account_id = this.search_items[index].id
          this.account_no = this.search_items[index].id_no
          this.account_name = this.search_items[index].member.last_name + ', ' + this.search_items[index].member.first_name + ' ' + this.search_items[index].member.middle_name
          this.last_name = this.search_items[index].member.last_name
          this.first_name = this.search_items[index].member.first_name
          this.$refs.form.validate()
        } else {
          this.reset()
        }
      } else {
        this.reset()
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    save_time_deposit_application() {
      this.saving = true
      this.alert = false
      // var flag = false
      // if (moment().format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
      //   || moment().format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
      //   flag = true
      // } else {
      //   var compareDate = moment()
      //   var startDate = moment(this.month_start, 'MMMM D, YYYY')
      //   var endDate = moment(this.month_end, 'MMMM D, YYYY')
      //
      //   flag = compareDate.isBetween(startDate, endDate) //false in this case
      // }
      // if (flag === false) {
      //   this.alert = true
      //   this.alert_message = 'Date Accomplished is Not Belong in Transaction Month!'
      //   this.saving = false
      // } else {
      var proceed = true;
      if (parseFloat(this.amount) < 100000) {
        this.alert = true
        this.alert_message = 'Time Deposit Amount did not meet the requirements of (100k).'
        this.saving = false
        proceed = false
      }
      if (parseFloat(this.terms) < 12) {
        this.alert = true
        this.alert_message = 'Time Deposit Term did not meet the requirements of (12 months).'
        this.saving = false
        proceed = false
      }
      if (proceed) {
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('date_of_savings', moment().format('YYYY-MM-D'));
          data.append('branch_id', this.branch_id);
          data.append('member_id', this.account_id);
          data.append('terms', this.terms);
          data.append('amount', this.amount);
          data.append('interest', this.total_interest_earned);
          data.append('total', this.total_deposit_with_interest);
          data.append('month_of', this.month_of);
          data.append('date_end', this.maturity_date);
          data.append('expected_month', this.maturity_month);
          data.append('last_name', this.last_name);
          data.append('first_name', this.first_name);
          data.append('is_less', 0);
          this.register_new_time_deposit_application(data)
            .then(response => {
              this.print_deposit_slip(response.data.id, moment().format('MMMM DD, YYYY'), moment().format('hh:mm:ss'), response.data.tdf_no)
              this.change_snackbar({
                show: true,
                color: 'success',
                text: this.last_name + ', ' + this.first_name + ' TIME DEPOSIT APPLICATION SAVED !!!',
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              console.log(error)
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
      // }
    },
    print_deposit_slip(id, dateeee, time, reg_id) {
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        pageSize: 'LETTER',
        content: [
          {text: 'Time Deposit, Cash Deposit', style: 'printed_label'},
          {text: 'REFDEP' + id, style: 'printed_label'},
          {text: this.account_no, style: 'printed_label'},
          {text: this.account_name, style: 'printed_label'},
          {text: 'PHP ' + this.amount, style: 'printed_label'},
          {text: 'TIME DEPOSIT', style: 'printed_label'},
          {text: dateeee + ' ' + time, style: 'printed_label'},
          {text: 'TDT: ' + reg_id, style: 'printed_label'},
          {text: 'Branch: ' + this.branch, style: 'printed_label'},
          {text: 'Depositor: ' + this.depositors_name.toUpperCase(), style: 'printed_label'},
        ],
        styles: {
          printed_label: {
            color: 'red',
            alignment: 'right',
            fontSize: 9,
            margin: [0, 4, 0, 0]//[left, top, right, bottom]
          },
        }
      }
      pdfMake.createPdf(docDefinition).open();
    }
  }
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
